*,
*:before,
*:after {
  box-sizing: border-box;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  line-height: inherit;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  font-size: $root-size;
  font-family: $font-primary;
}

* {
  &::selection {
    background-color: $color-primary;
    color: $color-white;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0;
  margin-bottom: 0;
}
.mobile-only {
  @include media-breakpoint-up(sm) {
    display: none;
  }
}
.desktop-only {
  display: none;
  @include media-breakpoint-up(sm) {
    display: block;
  }
}
strong {
  font-weight: 600;
}
a,
button {
  cursor: pointer;
  text-decoration: none;
  line-height: 1.2;
}

iframe,
video,
img {
  max-width: 100%;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid $color-white;
  -webkit-text-fill-color: $color-white;
  -webkit-box-shadow: 0 0 0px 1000px $color-navyblue-900 inset;
  transition: background-color 5000s ease-in-out 0s;
}

.visible {
  opacity: 1;
}

.hidden {
  opacity: 0;
  pointer-events: none;
}

.fade {
  position: absolute;
  transition: opacity ease-in-out .5s;
}