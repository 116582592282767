.page {
  width: 100%;
  height: auto;
  min-height: 100%;
  opacity: 1;
  animation: fade-in ease-in-out .5s;

  .container {
    width: 100%;
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    $padding-y: 2em;
    $padding-x: 1em;
    @include fluid-attr(padding-top, $padding-y * .7, $padding-y);
    @include fluid-attr(padding-bottom, $padding-y * .7, $padding-y);

    @include media-breakpoint-up(sm) {
      @include fluid-attr(padding-left, $padding-x * .7, $padding-x);
      @include fluid-attr(padding-right, $padding-x * .7, $padding-x);
    }

    &.static {
      position: static !important;
    }
  }

  &--home {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    overflow: auto;
    height: 100%;
    @include media-breakpoint-up(sm) {
      justify-content: space-between;
    }

    .home-video {
      max-height: 850px;
      position: absolute;
      overflow: hidden;
      z-index: -1;
      top: -25%;
      right: -10px;
      transform: rotate(140deg) scale(0.8);
      @include media-breakpoint-up(sm) {
        top: 0;
        right: unset;
        transform: translateX(0) scale(1.2);
      }
      @include media-breakpoint-up(xl) {
        top: 0;
        transform: translateX(25%) scale(1.2);
      }
    }

    .graphic {
      z-index: -1;
      top: 0;
      left: 0;
      position: absolute;
      transform: scale(5) translateX(1%) translateY(0%) rotate(90deg);

      @include media-breakpoint-up(lg) {
        transform: scale(2) translateX(-12%);
      }
    }

    .content {
      max-width: 691px;
      $height: 41.9375em;
      height: auto;
      top: 100px;
      //overflow: hidden;
      &__footnotes {
        display: flex;
        flex-direction: column;
        $padding-x: 1em;
        @include fluid-attr(padding-left, $padding-x * .7, $padding-x);
        @include fluid-attr(padding-right, $padding-x * .7, $padding-x);
        @include media-breakpoint-up(sm) {
          display: block;
          padding-left: 0;
          padding-right: 0;
        }
      }
      @include media-breakpoint-up(sm) {
        @include fluid-attr(height, $height * .5, $height);
      }
      margin-left: auto;
      margin-right: 0;
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      .logo {
        max-width: 200px;
        $padding-x: 1em;
        @include fluid-attr(padding-left, $padding-x * .7, $padding-x);
        @include fluid-attr(padding-right, $padding-x * .7, $padding-x);
        @include media-breakpoint-up(sm) {
          max-width: unset;
          text-align: right;
          padding-left: 0;
          padding-right: 0;

          img {
            max-width: unset;
          }
        }
      }
    }
  }

  &--cookie-policy {
    position: relative;
    overflow: auto;
    height: 100% !important;
  }

  &--questionnaire {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: auto;
    height: 100%;

    .questionnaire-video {
      position: relative;
      width: 100%;
      z-index: -1;
      //margin-top: -3em;
      &.answer-video {
        transform: translateY(-2.8em);
        @include media-breakpoint-up(sm) {
          transform: translateY(-5px);
        }
      }
      @include media-breakpoint-up(sm) {
        transform: translateY(-5px);
      }

      &--0 {
        //transform: rotate(-180deg) translateY(20%);
      }
      &--1 {
        transform: rotate(-180deg) translateY(20%);
      }
    }


    .answer-sign {
      $size: 10.375em;
      margin-top: -2.8125em;
      @include media-breakpoint-up(sm) {
        margin-top: 0;
      }
      @include fluid-attr(width, $size * .5, $size);
      @include fluid-attr(height, $size * .5, $size);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
        height: auto;
        pointer-events: none;

        &.times {
          max-width: 35px;
          @include media-breakpoint-up(sm) {
            max-width: 66.28px;
          }
        }

        &.check {
          max-width: 30px;
          @include media-breakpoint-up(sm) {
            max-width: 66.28px;
          }
        }
      }

      &-A {
        background-color: $color-navyblue-900;
      }

      &-B {
        background-color: $color-primary;
      }

      &-C {
        background-color: $color-pink-900;
      }
    }

    .answer {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: row;
      padding: 1em;
      $that: &;
      position: relative;
      cursor: pointer;
      @include media-breakpoint-up(sm) {
        padding: 0;
        flex-direction: column;
      }

      &:before {
        content: "";
        position: absolute;
        width: 100%;
        left: 0;
        height: 100%;
        z-index: 100;
        pointer-events: none;
        background-color: transparent;
        transition: all ease-in-out .5s;
      }

      &-graphic {
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        z-index: -1;
        @include media-breakpoint-up(sm) {
          position: absolute;
        }

        &--answer {
          margin-top: -12%;
          @include media-breakpoint-up(sm) {
            margin-top: 0;
          }
        }
      }

      &.answer--darken {
        &:before {
          pointer-events: auto;
          background-color: rgba(0, 0, 0, .5);
        }
      }

      &-A {
        background-color: rgba($color-navyblue-900, .8);
      }

      &-B {
        background-color: rgba($color-primary, .8);
      }

      &-C {
        background-color: rgba($color-pink-900, .8);
      }

      &--final {
        padding: 1em;
        flex-direction: column;
        justify-content: space-between;
        @include scrollBar();
        @include media-breakpoint-up(sm) {
          $padding-x: 5em;
          $padding-y: 6em;
          max-height: unset;
          overflow: unset;
          @include fluid-attr(padding-top, $padding-y * .5, $padding-y);
          @include fluid-attr(padding-bottom, $padding-y * .5, $padding-y);
          @include fluid-attr(padding-left, $padding-x * .5, $padding-x);
          @include fluid-attr(padding-right, $padding-x * .5, $padding-x);
        }

      }
    }
  }

  &--how-did-you-score {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    overflow: hidden;

    .video-anim-mobile {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: auto;
      margin: -10px auto auto auto;
      width: 100%;
      z-index: -1;
      @include media-breakpoint-up(sm) {
        display: none;
      }
    }

    .video-anim {
      position: absolute;
      display: none;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      margin: auto;
      width: 100%;
      z-index: -1;
      transform: rotate(-180deg) scale(1.3);
      @include media-breakpoint-up(sm) {
        display: block;
      }
    }

    .graphic {
      position: absolute;
      left: 0;
      z-index: -1;
      right: 0;
      bottom: auto;
      top: 0;
      margin: auto;
      transform: scale(3) translateX(-20%);
      @include media-breakpoint-up(sm) {
        bottom: 0;
        transform: unset;
      }
    }

    .content {
      background-color: lighten($color-pink-900, 10);
      max-width: 950px;
      margin: 0 auto;
      position: absolute;
      height: 70%;
      bottom: 0;
      @include media-breakpoint-up(sm) {
        height: 100%;
      }
      left: 0;
      right: 0;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: flex-end;
    }
  }

  &--thanks {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    overflow: hidden;

    .video-anim-mobile {
      position: absolute;
      top: -12%;
      right: 0;
      left: 0;
      bottom: auto;
      margin: auto;
      width: 100%;
      z-index: -1;
      @include media-breakpoint-up(sm) {
        display: none;
      }
    }

    .video-anim {
      position: absolute;
      top: 0;
      display: none;
      right: 0;
      left: 0;
      bottom: 0;
      margin: auto;
      width: 100%;
      z-index: -1;
      //transform: rotate(-10deg) scale(1.2);
      @include media-breakpoint-up(sm) {
        display: block;
      }
    }

    .graphic {
      position: absolute;
      left: 0;
      z-index: -1;
      right: 0;
      bottom: auto;
      top: 0;
      margin: auto;
      transform: scale(2) translateX(-10%);
      @include media-breakpoint-up(sm) {
        bottom: 0;
        transform: unset;
      }
    }

    .content {
      background-color: lighten($color-pink-900, 10);
      max-width: 950px;
      margin: 0 auto;
      width: 100%;
      height: 75%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      @include media-breakpoint-up(sm) {
        height: 100%;
      }

      &--pink {
        background-color: lighten($color-pink-900, 10);
      }

      &--green {
        background-color: lighten($color-primary, 10);
      }
    }
  }

  &--score {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    overflow: hidden;

    .score-video-mobile {
      position: absolute;
      top: -12%;
      right: 0;
      left: 0;
      bottom: auto;
      margin: auto;
      width: 100%;
      z-index: -1;
      @include media-breakpoint-up(sm) {
        display: none;
      }
    }

    .score-video {
      position: absolute;
      top: 0;
      display: none;
      right: 0;
      left: 0;
      bottom: 0;
      margin: auto;
      width: 100%;
      z-index: -1;
      transform: scale(1.1);
      @include media-breakpoint-up(sm) {
        display: block;
      }
    }

    .graphic {
      position: absolute;
      left: 0;
      z-index: -1;
      right: 0;
      bottom: auto;
      top: 0;
      margin: auto;
      transform: scale(2) translateX(-10%);
      @include media-breakpoint-up(sm) {
        bottom: 0;
        transform: unset;
      }
    }

    .user-score {
      max-width: 309px;
      width: 100%;
      margin: 0 auto;
      text-align: center;
      $padding: .1em;
      $size: 4.375em;
      border-radius: 60px;
      color: $color-white;
      @include fluid-attr(font-size, $size * .7, $size);
      @include fluid-attr(padding-top, $padding * .7, $padding);
      @include fluid-attr(padding-bottom, $padding * .7, $padding);
      background-color: $color-navyblue-900;
    }

    .content {
      background-color: lighten($color-primary, 10);
      max-width: 950px;
      margin: 0 auto;
      width: 100%;
      height: 75%;
      position: absolute;
      left: 0;
      right: 0;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      bottom: 0;
      @include media-breakpoint-up(sm) {
        height: 100%;
      }
    }
  }

  &--data-collection {
    //display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 0;
    height: 100%;
    overflow: auto;

    .content {
      padding: 0 1em;
    }

    .video-anim-mobile {
      position: absolute;
      top: -13%;
      right: 0;
      left: 0;
      bottom: auto;
      margin: auto;
      width: 100%;
      z-index: -1;
      transform: rotate(90deg) scale(1);
      @include media-breakpoint-up(sm) {
        display: none;
      }
    }

    .video-anim {
      position: absolute;
      top: 0;
      display: none;
      right: 0;
      left: 0;
      bottom: 0;
      margin: auto;
      width: 100%;
      z-index: -1;
      transform: rotate(180deg);
      @include media-breakpoint-up(sm) {
        display: block;
      }
    }

    .graphic {
      left: 0;
      z-index: -1;
      right: 0;
      bottom: 0;
      top: 0;
      margin: auto;
      transform: scale(1.8) translateX(74px) translateY(0);
      padding: 2em 0;
      @include media-breakpoint-up(sm) {
        position: absolute;
      }
    }

    .form {
      width: 100%;

      &__fields {
        max-width: 317px;
        margin: 0 auto;
      }

      select {
        $spacing: .25em;
        $padding-left: 7.5em;
        @include fluid-attr(padding-right, $spacing * .7, $spacing);
        @include fluid-attr(padding-bottom, $spacing * .7, $spacing);
        @include fluid-attr(padding-top, $spacing * .7, $spacing);
        @include fluid-attr(padding-left, $padding-left, $padding-left);
        background-color: transparent;
        width: 100%;
        font-family: "Poppins", sans-serif;
        color: $color-white;
        border: 1px solid $color-navyblue-900;
        border-radius: 25px;
        text-align: center;
      }

      input {
        background: transparent;
        box-shadow: none;
        border: 1px solid $color-navyblue-900;
        text-align: center;
        color: $color-white;
        $spacing: .25em;
        width: 100%;
        display: block;
        font-weight: 400;
        @include fluid-attr(padding, $spacing * .7, $spacing);
        border-radius: 25px;
        font-family: "Poppins", sans-serif;

        &::placeholder {
          text-align: center;
          color: $color-white;
        }
      }

      .input-group {
        display: flex;
        justify-content: flex-start;
      }

      .checkbox {
        position: relative;
        flex-grow: 1;
        height: 24px;
        width: 24px;
        min-width: 24px;
        max-width: 24px;
        background: $color-white;
        border: 1px solid $color-navyblue-900;
        padding: .1em;
        display: flex;
        justify-content: center;
        align-items: center;

        &-content {
          color: $color-white;
          font-family: "Poppins", sans-serif;
          font-weight: 400;
          font-size: .8em;
          display: flex;
          align-items: center;
          a {
            text-decoration: underline;
          }
        }

        input {
          opacity: 0;
          z-index: 100;
          position: absolute;
          width: 100%;
          height: 100%;
        }

        input:checked + img {
          opacity: 1;
        }

        img {
          opacity: 0;
          width: 100%;
          height: auto;
        }
      }
    }

    .content {
      position: relative;
      background-color: rgba($color-navyblue-900, .8);
      max-width: 950px;
      margin: 0 auto;
      width: 100%;
      //max-height: 50vh;
      @include media-breakpoint-up(sm) {
        max-height: unset;
      }
      overflow: auto;

      &:before {
        position: absolute;
        width: 100%;
        height: 100%;
        background: $color-white;
        content: "";
        margin-left: -1em;
        z-index: -1;
      }

      &-wrapper {
        max-width: 600px;
        margin: 0 auto;
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
