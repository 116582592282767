.footer {
  padding: 0 1em;
  border-top: 1px solid $color-primary;
  z-index: 10000;
  position: relative;
  width: 100%;
  background-color: $color-white;
  flex-grow: 0;
  .container {
    padding-left: 0;
    padding-right: 0;
  }
  &__content {
    display: flex;
    justify-content: space-between;

    &-buttons-group {
      display: flex;
      justify-content: space-between;
    }
  }
}