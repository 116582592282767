.spacer {
  @include spacers();
  &--desktop-only {
    display: none;
    @include media-breakpoint-up(sm) {
      display: block;
    }
  }
  
  &--mobile-only {
    display: block;
    @include media-breakpoint-up(sm) {
      display: none;
    }
  }
}