.button {
  margin: 0;
  padding: 0;
  border: none;
  outline: $color-primary;
  box-shadow: none;
  text-transform: uppercase;

  &--start {
    box-shadow: 0 0 10px 0 $color-primary;
    color: $color-primary;
    border: 1px solid $color-primary;
    background-color: $color-white;
    font-weight: 600;
    border-radius: 25px;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    $padding-y: 1em;
    $padding-x: 1.5em;
    $ratio: .7;
    animation: pulse 2s ease infinite;
    img {
      margin-left: .5em;
    }
    @include fluid-attr(padding-top, $padding-y * $ratio, $padding-y);
    @include fluid-attr(padding-bottom, $padding-y * $ratio, $padding-y);
    @include fluid-attr(padding-left, $padding-x * $ratio, $padding-x);
    @include fluid-attr(padding-right, $padding-x * $ratio, $padding-x);
  }

  &--next {
    box-shadow: 0 0 10px 0 rgba(0,0,0, .16);
    color: $color-white;
    background-color: $color-primary;
    font-weight: 600;
    border-radius: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    $padding-y: .5em;
    $padding-x: 1.1em;
    $ratio: .7;
    @include fluid-attr(padding-top, $padding-y * $ratio, $padding-y);
    @include fluid-attr(padding-bottom, $padding-y * $ratio, $padding-y);
    @include fluid-attr(padding-left, $padding-x * $ratio, $padding-x);
    @include fluid-attr(padding-right, $padding-x * $ratio, $padding-x);
  }
  &--center {
    justify-content: center !important;
  }
  &--quiz-next {
    box-shadow: 0 0 15px 0 $color-white;
    color: $color-white;
    background-color: transparent;
    border: 1px solid $color-white;
    font-weight: 600;
    border-radius: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    $padding-y: .5em;
    $padding-x: 1.1em;
    $ratio: .7;
    @include fluid-attr(padding-top, $padding-y * $ratio, $padding-y);
    @include fluid-attr(padding-bottom, $padding-y * $ratio, $padding-y);
    @include fluid-attr(padding-left, $padding-x * $ratio, $padding-x);
    @include fluid-attr(padding-right, $padding-x * $ratio, $padding-x);
  }
  &--full-width {
    width: 100%;
  }
  &--overlay {
    box-shadow: 0 0 10px 0 rgba(0,0,0, .16);
    color: $color-white;
    background-color: $color-navyblue-900;
    font-weight: 600;
    border-radius: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    $padding-y: .5em;
    $padding-x: 1.1em;
    $ratio: .7;
    @include fluid-attr(padding-top, $padding-y * $ratio, $padding-y);
    @include fluid-attr(padding-bottom, $padding-y * $ratio, $padding-y);
    @include fluid-attr(padding-left, $padding-x * $ratio, $padding-x);
    @include fluid-attr(padding-right, $padding-x * $ratio, $padding-x);
  }

  &--overlay-white {
    box-shadow: 0 0 15px 0 $color-primary;
    color: $color-primary;
    background-color: $color-white;
    border: 1px solid $color-primary;
    font-weight: 600;
    border-radius: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    $padding-y: .5em;
    $padding-x: 1.1em;
    $ratio: .7;
    @include fluid-attr(padding-top, $padding-y * $ratio, $padding-y);
    @include fluid-attr(padding-bottom, $padding-y * $ratio, $padding-y);
    @include fluid-attr(padding-left, $padding-x * $ratio, $padding-x);
    @include fluid-attr(padding-right, $padding-x * $ratio, $padding-x);
  }
  &--overlay-primary {
    box-shadow: 0 0 15px 0 $color-white;
    color: $color-white;
    background-color: transparent;
    border: 1px solid $color-white;
    font-weight: 600;
    border-radius: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    $padding-y: .5em;
    $padding-x: 1.1em;
    $ratio: .7;
    @include fluid-attr(padding-top, $padding-y * $ratio, $padding-y);
    @include fluid-attr(padding-bottom, $padding-y * $ratio, $padding-y);
    @include fluid-attr(padding-left, $padding-x * $ratio, $padding-x);
    @include fluid-attr(padding-right, $padding-x * $ratio, $padding-x);
  }

  &--unstyled {
    background: none;
    animation: none;
    text-transform: none;
    font-size: unset;
    font-family: unset;
    color: $color-primary;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba($color-primary, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba($color-primary, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba($color-primary, 0);
  }
}