.hr {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;

  &--default {
    height: 1px;
    background-color: $color-primary;
  }
}