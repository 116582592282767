.block {
  width: 100%;
  height: auto;
  display: block;
  &--flex {
    display: flex;
  }
  &--sm-row {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    @include media-breakpoint-up(sm) {
      flex-direction: column;
    }
  }
  &--flex-1 {
    flex: 1;
  }
  &--max-600 {
    margin: 0 auto;
    max-width: 600px;
    padding-right: 1em;
    padding-left: 1em;
    @include media-breakpoint-up(sm) {
      padding: 1em;
    }
    @include media-breakpoint-up(xxl) {
      padding: 0
    }
  }
  &--bordered-white {
    border: 1px solid $color-white;
    $padding: 1em;
    @include fluid-attr(padding, $padding * .7, $padding);
  }
  &--bordered-black {
    border: 1px solid $color-black;
    $padding: 1em;
    @include fluid-attr(padding, $padding * .7, $padding);
  }
  &--max-679 {
    max-width: 679px;
  }
  &--justify-center {
    justify-content: center;
    align-items: center;
  }
  &--mobile-only {
    @include media-breakpoint-up(sm) {
      display: none;
    }
  }
  &--desktop-only {
    display: none;
    @include media-breakpoint-up(sm) {
      display: block;
    }
  }
  @include background-colors();

  &--answer {
    &-A {
      height: 100%;
      width: 100%;
      background-color: $color-primary;
    }
    &-B {
      height: 100%;
      width: 100%;
      background-color: $color-navyblue-900;
    }
    &-C {
      height: 100%;
      width: 100%;
      background-color: $color-pink-900;
    }
  }
}