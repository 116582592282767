.wrapper {
  background: $color-navyblue-900;
  color: white;
  position: fixed;
  height: auto;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  z-index: 100000;
  padding-top: 1em;
  padding-bottom: 1em;

  &--options-visible {
    overflow: unset;
  }

  .section {
    transform: translateY(100vh);
    transition: transform 500ms ease-out;
    will-change: transform;
    position: absolute;
    visibility: hidden;
    margin: 0 1rem;

    @media all and (min-width: 600px) {
      margin: 0;
    }

    &.show {
      transform: translateY(0);
      position: relative;
      visibility: visible;
    }

    a {
      color: $color-primary
    }
  }

  .intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
      margin-bottom: 2rem;
      @include media-breakpoint-up(md) {
        text-align: center;
      }
    }

    @media all and (min-width: 600px) {
      flex-direction: row;
      justify-content: space-between;

      p {
        margin-bottom: 0;
        text-align: left;
      }
    }
  }

  .manage {
    p {
      margin-bottom: 2rem;

      @include media-breakpoint-up(md) {
        text-align: center;
      }

      @media all and (min-width: 600px) {
        text-align: left;
      }
    }
  }

  .toggles {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    @include media-breakpoint-up(md) {
      justify-content: center;
    }
    .toggle-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      margin-bottom: 1em;
      @include media-breakpoint-up(md) {
        margin: 1rem;
        justify-content: center;
      }

      .toggle {
        display: inline-flex;
        width: 4rem;
        height: 2rem;
        border-radius: 1.1rem;
        position: relative;
        cursor: pointer;
        margin-right: 1rem;

        &.disabled {
          cursor: not-allowed;
          opacity: 0.8;
        }

        &:before {
          content: "";
          display: block;
          width: 2rem;
          height: 2rem;
          border-radius: 1.1rem;
          transform: translateY(-50%);
          transition: left 200ms ease-in-out;
          position: absolute;
          top: 50%;
          left: 0;
        }

        &.on {
          background: white;

          &:before {
            background: $color-primary;
            left: 2rem;
          }
        }

        &.off {
          background: $color-black;

          &:before {
            background: white;
          }
        }
      }

      span {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 0.8rem;
        width: 1.5rem;
        height: 1.5rem;
        padding-bottom: 0.1rem;
        padding-right: 0.1rem;
        margin-left: 0.5rem;
        background-color: $color-navyblue-900;
        font-style: italic;
        position: relative;

        &:before {
          content: attr(data-tooltip);
          display: none;
          padding: 1rem;
          width: 45vw;
          border-radius: 0.25rem;
          position: absolute;
          top: -1rem;
          left: 50%;
          transform: translate(-50%, -100%);
          background: $color-primary;
          font-weight: initial;
          text-align: center;
          justify-content: center;
          color: white;
          font-style: normal;
          z-index: 1;
          $font-size: 1em;
          @include fluid-attr(font-size, $font-size * .6, $font-size);
        }

        &:after {
          content: "";
          display: none;
          border-radius: 0.25rem;
          width: 1rem;
          height: 1rem;
          position: absolute;
          top: -1.6rem;
          left: 50%;
          transform: translateX(-50%) rotateZ(45deg);
          background: $color-primary;
        }

        &:hover:before,
        &:hover:after {
          display: inline-flex;
        }
      }
    }

    @media all and (min-width: 700px) {
      justify-content: space-between;

      .toggle-wrapper {
        margin: 0;
      }
    }
  }
}