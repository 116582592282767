$ratio: .5;
.text {
  $size: 1.25em;

  a {
    text-decoration: underline;
  }
  small {
    font-size: .5em;
    display: inline-block;
  }
  &--weight-200 {
    font-weight: 200;
  }
  sup {
    font-size: .5em;
  }
  &--mobile-max-height-200 {
    max-height: 120px;
    overflow: auto;
    @include scrollBar();
    @include media-breakpoint-up(sm) {
      max-height: unset;
      overflow: unset;
    }
  }
  &--mobile-center {
    text-align: center;
    @include media-breakpoint-up(sm) {
      text-align: left;
    }
  }
  &--desktop-center {
    text-align: left;
    @include media-breakpoint-up(sm) {
      text-align: center;
    }
  }
  @include fluid-attr(font-size, $size * .5, $size);
  @include text-colors();
  &--small {
    font-size: 0.75em;
  }
  &--medium-to-small {
    $size: 1em;
    @include fluid-attr(font-size, $size * .9, $size);
  }
  &--medium {
    $size: 1.25em;
    @include fluid-attr(font-size, $size * .5, $size);
  }
  &--large {
    $size: 1.5625em;
    @include fluid-attr(font-size, $size * $ratio, $size);
  }

  &--padding-1em {
    padding: 1em;
  }

  &--pointer {
    cursor: pointer;
  }

  &--question-label {
    $size: 1.5625em;
    @include fluid-attr(font-size, $size * $ratio, $size);
    box-shadow: 0 0 10px 0 $color-white;
    font-weight: 600;
    border-radius: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    $padding-y: .2em;
    $padding-x: 1.1em;
    $ratio: .7;
    @include fluid-attr(padding-top, $padding-y * $ratio, $padding-y);
    @include fluid-attr(padding-bottom, $padding-y * $ratio, $padding-y);
    @include fluid-attr(padding-left, $padding-x * $ratio, $padding-x);
    @include fluid-attr(padding-right, $padding-x * $ratio, $padding-x);
  }

  &--row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    p {
      $margin: 1em;
      @include fluid-attr(margin-right, $margin * .5, $margin);
      word-break: break-word;
      &:first-child {
        font-weight: 700;
        white-space: nowrap;
      }
      &:last-child {
        font-weight: 200;
      }
    }
  }

  ol {
    li {
      &::marker {
        margin-left: -20px;
        content: counter();
      }
    }
  }
}