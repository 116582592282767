$ratio: .7;
.title {
  @include text-colors();
  @include weights();
  .no-wrap {
    white-space: nowrap;
  }
  &--mobile-center {
    text-align: center;
    @include media-breakpoint-up(sm) {
      text-align: left;
    }
  }
  &--mobile-min-200 {
    max-height: 135px;
    overflow: auto;
    @include scrollBar();
    @include media-breakpoint-up(sm) {
      max-height: unset;
      overflow: unset;
    }
  }
  &--question {
    //max-height: 176px;
    overflow: auto;
    position: relative;
    @include scrollBar();
    //margin: 0 1em;
    @include media-breakpoint-up(sm) {
      max-height: unset;
      overflow: unset;
      margin: 0;
      height: 100%;

      h3 {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }
  sup {
    font-size: .5em;
  }
  &--center {
    text-align: center;
  }
  h1 {
    $size: 3.125em;
    @include fluid-attr(font-size, $size * .5, $size);
  }

  h2 {
    $size: 3em;
    @include fluid-attr(font-size, $size * .7, $size);
  }

  h3 {
    $size: 1.875em;
    small {
      display: inline-block;
      font-size: .5em;
    }
    @include fluid-attr(font-size, $size * .7, $size);
  }

  h4 {
    $size: 1em;
    @include fluid-attr(font-size, $size * .7, $size);
  }

  &--home {
    color: $color-primary;
    margin-bottom: .5em;
    @include media-breakpoint-up(sm) {
      margin-bottom: 0;
    }
    h1 {
      line-height: 1.4;
      $size: 2.8125em;
      font-weight: 400;
      @include fluid-attr(font-size, $size * .3, $size);
      span {
        $size: 1.5em;
        font-weight: 600;
        line-height: 1.2;
        @include media-breakpoint-up(sm) {
          line-height: inherit;
        }
        @include fluid-attr(font-size, $size, $size);
      }
    }
  }

  &--question-info {
    font-family: 'Nunito Sans', sans-serif;
    h3 {
      text-transform: uppercase;
      font-weight: 600;
      color: $color-primary;
      span {
        color: rgba($color-primary, .50);
      }
    }
  }

  &--answer {
    &-A {
      color: $color-navyblue-900;
    }

    &-B {
      color: $color-primary;
    }

    &-C {
      color: $color-pink-900;
    }
  }
}