.layout {
  $ratio: .5;
  margin: 0 auto;
  position: relative;
  flex-grow: 1.2;
  &--z-0 {
    z-index: 0;
  }
  &--two-column-text {
    @include media-breakpoint-up(sm) {

      -webkit-column-count: 2;
      -moz-column-count: 2;
      column-count: 2;
      -webkit-column-gap: 20px;
      -moz-column-gap: 20px;
      column-gap: 20px;
    }
  }
  &--full-height {
    height: 100%;
  }
  &--full-width {
    width: 100%;
  }
  @include child-flex-alignment();
  &--relative {
    position: relative;
  }

  &--static {
    position: static;
  }

  &--spacing-1em {
    $spacing: 1em;
    @include fluid-attr(padding-left, $spacing * $ratio, $spacing);
    @include fluid-attr(padding-right, $spacing * $ratio, $spacing);
  }
  &--spacing-3em {
    $spacing: 3em;
    @include fluid-attr(padding-left, $spacing * .8, $spacing);
    @include fluid-attr(padding-right, $spacing * .8, $spacing);
  }
  &--container-large {
    width: 100%;
    max-width: $responsive-max;
  }

  &--flex {
    display: flex;
  }

  &--column {
    flex-direction: column;
  }

  &__row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 100%;
    &--wrap-reverse {
      flex-wrap: wrap-reverse;
    }
    &--auto {
      height: auto;
    }

    &--desktop-spacing-1em {
      @include media-breakpoint-up(sm) {
        $spacing: 1em;
        @include fluid-attr(margin, -$spacing * $ratio, -$spacing);
      }
    }
    &--spacing-1em {
      $spacing: 1em;
      @include fluid-attr(margin, -$spacing * $ratio, -$spacing);
    }
    &--spacing-\.5em {
      $spacing: 0.5em;
      @include fluid-attr(margin, -$spacing * $ratio, -$spacing);
    }
    &--spacing-2em {
      $spacing: 2em;
      @include fluid-attr(margin, -$spacing * $ratio, -$spacing);
    }
  }

  &__column {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    @include layout();
    @include child-flex-alignment();

    &--min-300 {
      min-height: 300px;
      @include media-breakpoint-up(sm) {
        min-height: unset;
      }
    }

    &--row {
      flex-direction: row;
    }

    &--spacing-1em {
      $spacing: 1em;
      @include fluid-attr(padding, $spacing * $ratio, $spacing);
    }
    &--spacing-\.5em {
      $spacing: 0.5em;
      @include fluid-attr(padding, $spacing * $ratio, $spacing);
    }

    &--spacing-2em {
      $spacing: 2em;
      @include fluid-attr(padding,  $spacing * $ratio, $spacing);
    }
  }
}