.popup {
  margin: 0;
  height: 100%;
  width: 100%;
  z-index: 1000000;
  background: rgba($color-black, .5);
  position: fixed;
  overflow: hidden;
  justify-content: center;
  top: 0;
  left: 0;
  display: flex;

  &--visible {
    opacity: 1;
    transition: all ease-in-out .5s;
  }

  &--hidden {
    opacity: 0;
    transition: all ease-in-out .5s;
    pointer-events: none;
  }

  &-trigger {
    cursor: pointer;
    display: inline-block;
  }

  &-dialog {
    position: absolute;
    box-shadow: 0 0 8px 0 rgba($color-black, .16);
    display: flex;
    flex-direction: column;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background: $color-white;

    &-close {
      position: relative;
      padding: .5em;
      margin-left: auto;
      &.overlay-primary {
        background-color: $color-primary;
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }
      //@include media-breakpoint-up(sm) {
      //  position: absolute;
      //  top: 2em;
      //  right: 2em;
      //}
      img {
        max-width: 1em;
        @include media-breakpoint-up(sm) {
          max-width: 2em;
          padding-left: .4em;
          padding-right: .4em;
        }
      }
    }

    &__content {
      height: 100%;
      width: 100%;
      overflow-y: auto;
      overflow-x: visible;
      @include scrollBar();
    }
  }
}
