$root-size: 16px;
$responsive-min: 16em;
$responsive-max: 105em;
$weights: 100, 200, 300, 400, 500, 600, 700;
$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1500px,
) !default;

$font-primary: "Poppins", sans-serif;

$color-primary: #4EB7BA;
$color-white: #fff;
$color-black: #000;

$color-navyblue-900: #004F6E;
$color-navyblue-850: #004E6D;
$color-navyblue-800: rgba(#004E6D, .8);
$color-pink-900: #D92990;

$text-colors: (
        "color-primary": $color-primary,
        "color-navyblue-900": $color-navyblue-900,
        "color-navyblue-800": $color-navyblue-800,
        "color-pink-900": $color-pink-900,
        "color-white": $color-white
);
$background-colors: (
        "background-navyblue-900": $color-navyblue-900,
        "background-navyblue-800": $color-navyblue-800,
        "background-pink-900": $color-pink-900,
);