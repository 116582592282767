.ref {
  min-height: 100%;
  width: 100%;
  background: $color-primary;
  $padding: 4em;
  @include fluid-attr(padding-top, $padding * .5, $padding);
  @include fluid-attr(padding-bottom, 1em * .5, 1em);
  @include fluid-attr(padding-left, 1em * .5, 1em);
  @include fluid-attr(padding-right, 1em * .5, 1em);
  padding-top: 4em;
}